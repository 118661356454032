<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <span class="menu_title">学术研究</span>
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        :class="{ showButton: !saveButton }"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item
                    prop="xsyjcgmc"
                    label="学术研究成果名称"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.saveJson.xsyjcgmc"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <!-- <el-form-item prop="CJSJ" label="时间" class="from-item-block">
                    <el-date-picker
                        v-model="formdata.saveJson.CJSJ"
                        type="date"
                        placeholder="选择日期"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    >
                    </el-date-picker>
                </el-form-item> -->
                <el-form-item
                    prop="yjfx"
                    label="研究方向"
                    class="from-item-block"
                >
                    <el-select
                        v-model="yjfxList"
                        multiple
                        :disabled="!isEdit"
                        @change="changeYjfx"
                    >
                        <el-option
                            v-for="(item, index) in YJFXList"
                            :key="`${index}`"
                            :value="item.CODE"
                            :label="item.LABEL"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item
                    prop="cjry"
                    label="参加人员"
                    class="from-item-block"
                >
                    <el-input
                        v-model="formdata.saveJson.cjry"
                        :disabled="!isEdit"
                    >
                    </el-input>
                </el-form-item>
                <div style="border:1px #ccc solid;padding-right:20px">
                    <el-form-item
                        prop="xsyjcglb"
                        label="学术研究成果类别"
                        class="from-item-block"
                    >
                        <el-select
                            v-model="formdata.saveJson.xsyjcglb"
                            :disabled="!isEdit"
                            @change="changeXsyjcglb()"
                        >
                            <el-option
                                v-for="(item, index) in XSYJCGLBList"
                                :key="index"
                                :value="item.CODE"
                                :label="item.LABEL"
                            ></el-option>
                        </el-select>
                    </el-form-item>
                    <!--  科研课题 1 著作 2 学术论文 3 -->
                    <span v-if="formdata.saveJson.xsyjcglb == '1'">
                        <el-form-item
                            prop="zyyjnr"
                            label="主要研究内容"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.saveJson.zyyjnr"
                                :disabled="!isEdit"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item
                            prop="wtdw"
                            label="委托单位"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.saveJson.wtdw"
                                :disabled="!isEdit"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item label="课题级别" class="from-item-block">
                            <el-select
                                v-model="formdata.saveJson.ktjb"
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item, index) in KTJBList"
                                    :key="`${index}`"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="课题金额" class="from-item-block">
                            <el-input
                                onkeyup="this.value = this.value.replace(/[^\d.]/g,'');"
                                v-model="formdata.saveJson.ktje"
                                :disabled="!isEdit"
                                placeholder="最多不能超过10000"
                                @input="changeMoney()"
                                clearable
                            >
                                <template slot="append">万元</template>
                            </el-input>
                        </el-form-item>
                    </span>
                    <span v-if="formdata.saveJson.xsyjcglb == '2'">
                        <el-form-item label="著作方式" class="from-item-block">
                            <el-select
                                v-model="formdata.saveJson.zzfs"
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item, index) in zzfsList"
                                    :key="`${index}`"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item
                            label="主要研究内容"
                            class="from-item-block"
                        >
                            <el-input
                                v-model="formdata.saveJson.zzzyyjnr"
                                :disabled="!isEdit"
                            >
                            </el-input>
                        </el-form-item>
                    </span>
                    <span v-if="formdata.saveJson.xsyjcglb == '3'">
                        <el-form-item label="期刊名称" class="from-item-block">
                            <el-input
                                v-model="formdata.saveJson.qkmc"
                                :disabled="!isEdit"
                            >
                            </el-input>
                        </el-form-item>
                        <el-form-item label="期刊类型" class="from-item-block">
                            <el-select
                                v-model="formdata.saveJson.qklx"
                                :disabled="!isEdit"
                            >
                                <el-option
                                    v-for="(item, index) in qklxList"
                                    :key="`${index}`"
                                    :value="item.CODE"
                                    :label="item.LABEL"
                                ></el-option>
                            </el-select>
                        </el-form-item>
                    </span>
                </div>

                <el-form-item label="文件" class="from-item-block">
                    <basic-upload
                        ref="uploadFile"
                        :isEdit="isEdit"
                        :itemId="itemId"
                        :metaData.sync="formdata.metaJson"
                        basicType="document"
                        :disabled="!isEdit"
                        :maxSize="500"
                    ></basic-upload>
                </el-form-item>
            </el-form>
        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import infoMixin from "../0_com_js/info_mixin.js";
import snButton from "../0_com_js/edit-button.js";
export default {
    name: "xsyj",
    mixins: [infoMixin],
    data() {
        return {
            snButton: snButton,
            isEdit: false,
            saveButton: true,
            formdata: {
                saveJson: {
                    xsyjcgmc: "", // 学术研究成果名称
                    yjfx: "", // 研究方向
                    cjry: "", // 参加人员
                    xsyjcglb: "", // 学术研究成果类别
                    zyyjnr: "", // 主要研究内容 ------没找到
                    wtdw: "", // 委托单位
                    ktjb: "", // 课题级别
                    ktje: "", // 课题金额
                    zzfs: "", // 著作方式
                    zzzyyjnr: "", // 著作主要研究内容
                    qkmc: "", // 期刊名称
                    qklx: "", // 期刊类别
                },
                metaJson: [],
                heritageId: "",
                fileID: "",
                originalFileID: "",
                itemID: "34",
                userName: "",
                userId: "",
            },
            rules: {
                xsyjcgmc: [
                    {
                        required: true,
                        message: "请填写学术研究成果名称",
                        trigger: "blur",
                    },
                ],
                yjfx: [
                    {
                        required: true,
                        message: "请选择研究方向",
                        trigger: "blur",
                    },
                ],
                xsyjcglb: [
                    {
                        required: true,
                        message: "请选择学术研究成果类别",
                        trigger: "change",
                    },
                ],
                // YCSSCDVALUE: [
                //     { required: true, message: '请选择遗产受损程度', trigger: 'blur' }
                // ]
            },
            KTJBList: [], // 课题级别 枚举值列表
            qklxList: [], // 期刊类型 枚举值列表
            XSYJCGLBList: [], // 学术研究成果类别 枚举值列表
            YJFXList: [], // 研究方向 枚举值列表
            zzfsList: [], // 著作方式 枚举值列表
            yjfxList: [], // 研究方向 多选
        };
    },
    created() {
        this.GetEnum();
    },
    mounted() {},
    methods: {
        ...mapActions(["getAllEnumInfobytableID"]),
        async GetEnum() {
            let res = await this.getAllEnumInfobytableID({
                bid: "34",
            });
            let enumList = res || [];
            enumList.forEach((v) => {
                if (v.COLUMNID === "KTJB") {
                    this.KTJBList = v.dataItems;
                }
                if (v.COLUMNID === "QKLX") {
                    this.qklxList = v.dataItems;
                }
                if (v.COLUMNID === "XSYJCGLB") {
                    this.XSYJCGLBList = v.dataItems;
                }
                if (v.COLUMNID === "YJFX") {
                    this.YJFXList = v.dataItems;
                }
                if (v.COLUMNID === "ZZFS") {
                    this.zzfsList = v.dataItems;
                }
            });
        },
        // 改变研究方向时  由数组转变为 表单的值
        changeYjfx() {
            this.formdata.saveJson.yjfx = this.yjfxList.join(",");
            this.$refs.formdata.validateField("yjfx");
        },
        getDataNext() {
            this.yjfxList = this.formdata.saveJson.yjfx.split(",");
            this.formdata.saveJson.xsyjcglb = String(
                this.formdata.saveJson.xsyjcglb
            );
            this.formdata.saveJson.zzfs = this.formdata.saveJson.zzfs
                ? String(this.formdata.saveJson.zzfs)
                : "";
            this.formdata.saveJson.qklx = this.formdata.saveJson.qklx
                ? String(this.formdata.saveJson.qklx)
                : "";
        },
        // 学术研究成果类别
        changeXsyjcglb() {
            switch (this.formdata.saveJson.xsyjcglb) {
                case "1": //科研课题
                    this.formdata.saveJson.zzfs = ""; // 著作方式
                    this.formdata.saveJson.zzzyyjnr = ""; // 著作主要研究内容

                    this.formdata.saveJson.qkmc = ""; // 期刊名称
                    this.formdata.saveJson.qklx = ""; // 期刊类别
                    break;
                case "2": //著作
                    this.formdata.saveJson.zyyjnr = ""; // 主要研究内容 学术
                    this.formdata.saveJson.wtdw = ""; // 委托单位
                    this.formdata.saveJson.ktjb = ""; // 课题级别
                    this.formdata.saveJson.ktje = ""; // 课题金额

                    this.formdata.saveJson.qkmc = ""; // 期刊名称
                    this.formdata.saveJson.qklx = ""; // 期刊类别
                    break;
                case "3": //学术论文
                    this.formdata.saveJson.zyyjnr = ""; // 主要研究内容 学术
                    this.formdata.saveJson.wtdw = ""; // 委托单位
                    this.formdata.saveJson.ktjb = ""; // 课题级别
                    this.formdata.saveJson.ktje = ""; // 课题金额

                    this.formdata.saveJson.zzfs = ""; // 著作方式
                    this.formdata.saveJson.zzzyyjnr = ""; // 著作主要研究内容

                    break;
            }
        },
        // 金额判断 最大1亿
        changeMoney() {
            if (this.formdata.saveJson.ktje > 10000) {
                this.formdata.saveJson.ktje = 10000;
            }
        },
    },
};
</script>

<style></style>
